/*
  Page CSS
*/

.body{
  background-color: #C9D1D5;
  height: 100vh;
  background:linear-gradient(rgba(75, 72, 72, 0.5),transparent);
 
}

.center {
  margin: auto;
  height: fit-content;
  text-align: center;
}

.name{
  padding: 0px;
  border: none;
  background-color: transparent;
  color: #0C243C;
  font-size: 80px;
  font-weight: 500;
}

/*
  Home CSS
*/

.descContainer{
  margin-top: 10%;
  text-align: left;
}

.jobTitle{
  display: flex;
  font-size: 40px;
  font-weight: bold;
}

.homeDesc{
  font-size: 25px;
  font-weight: bold;
  text-align: left;
  color: #0C243C
}
.connect{
  margin-top: 50px;
  font-size: 30px;
  font-weight: bold;
}

.downloadCV{
  color: #0C243C;
  text-decoration: none;
  font-weight: bold;
  font-size: 20px;
}

.downloadCVButton{
  border-radius: 15px;
  margin: 10px 20px 0px 0px;
  padding: 15px 20px 0px 20px;
  border-style: solid;
  border-color: #0C243C;
  background: transparent;
}
.iconButton{
  margin: 20px 10px 0px 0px;
  background-color: transparent;
  border-radius: 50%;
  border: none;
  font-size: 20px;
}

.icon{
  color: #0C243C;
  font-size: 50px;
}

.buttonContainer{
  text-align: left;
}

.iconContainer{
  text-align: left;
}

.headshotContainer {
  display: block;
  margin: auto;
  margin-top: 15%;
  width: 60%;
  border-radius: 100%;
  border-color: #0C243C;
  border-width: 5px;
  border-style: solid;
}

.headshot{
  border-radius: 100%;
  width: 100%;
  height: 100%;
}

/*
  About CSS
*/

.aboutDesc{
  font-size: 25px;
  margin: 50px auto auto auto;
  width: 80%;
  font-weight: bold;
  text-align: left;
  color: #0C243C
}

.aboutIcon{
  display: block;
  font-size: 100px;
  margin: 50px auto auto auto;
  font-weight: bold;
  text-align: center;
  color: #0C243C
}
.rps{
  margin: 10px auto auto auto;
  width: 250px;
}
.rpsls{
  margin: 10px auto auto auto;
  width: 250px;
}
.graduation{
  border-radius: 30px 30px 0px 0px;
  border-style: solid;
  border-width: 4px;
  border-color: #0C243C;
  width: 200px;
  height: 250px;
}

.molli{
  border-radius: 30px 30px 0px 0px;
  margin-bottom: 20px;
  border-style: solid;
  border-width: 4px;
  border-color: #0C243C;
  width: 200px;
  height: 250px;
}

.picture2 {
  display: none;
}
.thumbnail:hover .picture1 {
  display: none;
}
.thumbnail:hover .picture2 {
  display: block;
}

/*
  Projects CSS
*/

.projectGrid{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 20px;
  padding: 20px;
}
.projectItem{
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  font-size: 18px;
}
.projectContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Ensures the container takes full height */
  margin: 10px 0px 10px 0px;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 25px;
  border-style: solid;
  border-color: #0C243C;
  border-radius: 25px;
  box-shadow: 6px 6px 2px 1px #0C243C;

}

.projectBottomContent {
  margin-top: auto; /* Pushes this section to the bottom */
}

.filterButtons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
}
.projectType{
  text-align: left;
  padding: 2px 4px 2px 4px;
  font-size: 12px;
  border:none;
  width: fit-content;
  font-weight: bold;
  color: #C9D1D5;
  background-color: #0C243C;
}
.projectTitle{
  font-size: 20px;
  font-weight: bolder;
}

.projectDesc{
  font-size: 15px;
  text-align: left;
  flex-grow: 1; /* This will ensure all descriptions grow to the size of the largest one */
  font-weight: bold;
}
.projectButtonsContainer{
  text-align: center;
 
}

.projectButton{
  border-radius: 15px;
  margin: 0px 15px 10px 15px;
  padding: 15px 20px 0px 20px;
  border-style: solid;
  font-weight: bold;
  border-color: #0C243C;
  background: transparent;
}

.projectGit{
  margin-right: 5px;
}

.projectLink{
  color: #0C243C;
  text-decoration: none;
}
.projectImage {
  width: 300px;    /* Set your desired width */
  height: 200px;   /* Set your desired height */
  object-fit: contain; /* Ensures the whole image fits within the container */
  display: block;  /* Treat the image as a block element */
  margin: auto;    /* Horizontally centers the image */
  background: none;
}

.projectImageContainer {
  display: flex;             /* Flexbox to handle centering */
  justify-content: center;    /* Center horizontally */
  align-items: center;        /* Center vertically */
  width: 100%;                /* Full width of the container */
  height: 200px;              /* Height to match the image height */
}

.projectPicture2{
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  
  width: 30%;
  border-radius: 25px;
}

.techDisplay{
  display: flex;
  margin-top: 10px;
  justify-content: left;
  flex-direction: row;
  flex-wrap: wrap;
}
.projectTech{
  margin: 2px;
  padding: 2px 4px 2px 4px;
  font-size: 12px;
  width: fit-content;
  font-weight: bold;
  color: #C9D1D5;
  background-color: #0C243C;
}


/*
  Navbar css
*/

.mainbar{
  display: flex;
  justify-content: right;
  background: transparent;

}

.regularMenu{
  display: flex;
  flex-direction: row;
}

.allContent{
  padding: 0px 40px 40px 40px;
}
.bottombar{
  display: flex;
  justify-content: center;
  font-size: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #0C243C;
  color: white
}

.barlinks{
  color: #0C243C;
  font-size: 25px;
  font-weight: bold;
  margin: 15px;
  text-decoration: none;
}

.barlinks-active{
  color: #0C243C;
  font-size: 25px;
  margin: 15px;
  font-weight: bold;
  text-decoration: overline 3px;
}

.barlinks::before {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: #0C243C;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.barlinks:hover::before {
  transform: scaleX(1);
}


/*Mobile Menu */
.mobileMenu{
  display:none;
}

@media screen and (max-width:769px){

  .projectGrid{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 0px;
  }
  .projectItem{
    padding: 10px;
    text-align: center;
    border-radius: 8px;
    font-size: 18px;
  }

  .name{
    text-align: center;
    font-size: 50px;
    font-weight: 500;
  }

  .barlinks{
    color: #0C243C;
    font-size: 25px;
    font-weight: bold;
    margin: 15px;
    position: relative;
    text-decoration: none;
  }
  .barlinks::before {
   display: none;
  }

  .regularMenu{
    display: none;
  }

  .mobileMenu{
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: center;
  }

  .menu{
    display: flex;
    flex-direction: column;
  }

  .menuDesc{
    display: inline-block;
    border: none;
    background-color: transparent;
    color:#0C243C;
    text-align: right;
    margin-right: 10px;
  }

  .menuButton{
    border: none;
    background-color: transparent;
    color:#0C243C;
    text-align: right;
    margin: 20px 20px 0px 0px;
  }

  .descContainer{
    margin-top: 20px;
    text-align: center;
    width: auto;
  }
  .iconContainer{
    text-align: center;
  }
  .homeDesc{
    display: block;
    justify-content: center;
    text-align: center;
    color: #0C243C;
    font-size: 20px;
    font-weight: bold;
  }
  
  .jobTitle{
    font-size: 40px;
    display: block;
    justify-content: center;
    font-weight: bold;
    }

    .buttonContainer{
      text-align: center;
    }

 
  .body{
    background-color: #C9D1D5;
    height: 150vh;
    background:linear-gradient(rgba(75, 72, 72, 0.5),transparent);
   
  }
  .projectContainer{
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    justify-content: center;
    height: 95%;
    width: 100%;
    padding: 25px;
    border-style: solid;
    border-color: #0C243C;
    border-radius: 25px;
    box-shadow: 6px 6px 2px 1px #0C243C;
   
  }
  .techDisplay{
    display: flex;
    margin-top: 10px;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .rps{
    margin: 10px auto auto auto;
    width: 80%
  }
  .rpsls{
    margin: 10px auto auto auto;
    width: 80%
  }
  .projectImage {
    width: 150px;    /* Set your desired width */
    height: 150px;   /* Set your desired height */
    object-fit: contain; /* Ensures the whole image fits within the container */
    display: block;  /* Treat the image as a block element */
    margin: auto;    /* Horizontally centers the image */
    background: none;
  }
  
  
}